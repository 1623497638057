import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

// Canvas
const canvas = document.querySelector(".hearts");

// Loaders
const gltfLoader = new GLTFLoader();

// Scene
const scene = new THREE.Scene();

// Geometry
let heart = null;
const material = new THREE.MeshBasicMaterial({ color: 0x5d9e74 });
gltfLoader.load("../heart.gltf", (gltf) => {
  heart = gltf.scene;
  heart.traverse((child) => {
    if (child.isMesh) child.material = material;
  });
  scene.add(heart);
  heart.position.set(-2.3, 0.3, 3.5);
});

let heart2 = null;
gltfLoader.load("../heart.gltf", (gltf) => {
  heart2 = gltf.scene;
  heart2.traverse((child) => {
    if (child.isMesh) child.material = material;
  });
  scene.add(heart2);
  heart2.position.set(0, 0.3, 3.5);
});

let heart3 = null;
gltfLoader.load("../heart.gltf", (gltf) => {
  heart3 = gltf.scene;
  heart3.traverse((child) => {
    if (child.isMesh) child.material = material;
  });
  scene.add(heart3);
  heart3.position.set(2.3, 0.3, 3.5);
});

/**
 * Sizes
 */
const sizes = {
  width: canvas.clientWidth,
  height: canvas.clientHeight,
  pixelRatio: Math.min(window.devicePixelRatio, 2),
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = canvas.clientWidth;
  sizes.height = canvas.clientHeight;
  sizes.pixelRatio = Math.min(window.devicePixelRatio, 2);

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(sizes.pixelRatio);
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.set(0, 0, 10);
scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(sizes.pixelRatio);

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  if (heart) {
    heart.rotation.y = elapsedTime * 1;
  }

  if (heart2) {
    heart2.rotation.y = elapsedTime * 0.5;
  }

  if (heart3) {
    heart3.rotation.y = elapsedTime * 1.2;
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
