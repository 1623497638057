import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";

// timer animation
const timer = document.querySelector(".timer");

let numberAnimate = anime({
  targets: timer,
  innerHTML: ["52"],
  round: 1,
  direction: "alternate",
  easing: "linear",
  duration: 3500,
  loop: true,
  autoplay: true,
});

// Wave
// https://library.superhi.com/posts/how-to-draw-and-animate-an-svg-wave-using-javascript
let xs = [];
var squiggleLength = 250;

function myFunction(x) {
  if (x.matches) {
    // If media query matches
    squiggleLength = 700;
  } else {
    squiggleLength = 200;
  }
}

function myFunctionTwo(y) {
  if (y.matches) {
    // If media query matches
    squiggleLength = 200;
  }
}

// Create a MediaQueryList object
var x = window.matchMedia("(max-width: 1024px)");
var y = window.matchMedia("(max-width: 500px)");

// Call listener function at run time
myFunction(x);
myFunctionTwo(y);

for (var i = 0; i <= squiggleLength; i++) {
  xs.push(i);
}

// Attach listener function on state changes
x.addEventListener("change", function () {
  myFunction(x);
});
y.addEventListener("change", function () {
  myFunctionTwo(y);
});

let time = 0;

const wave = document.querySelector("#wave");
const height = wave.clientHeight / 2;

function animate() {
  let points = xs.map((x) => {
    let y = height + 10 * Math.sin((x + time) / 10);

    return [x, y];
  });

  let path =
    "M" +
    points
      .map((p) => {
        return p[0] + "," + p[1];
      })
      .join(" L");

  document.querySelector("#wave path").setAttribute("d", path);

  time += 1;

  requestAnimationFrame(animate);
}

animate();

// Carousel
const messageCarousels = document.querySelectorAll(".message-container h6");

messageCarousels.forEach((ecarousel) => {
  const messageSpanTag = ecarousel.querySelector(".span");
  const messageSpanTagWidth = messageSpanTag.clientWidth;

  for (let i = 0; i < 40; i = i + 1) {
    ecarousel.appendChild(messageSpanTag.cloneNode(true));
  }

  const messageMovementTimeline = gsap.timeline({
    repeat: -1,
  });

  messageMovementTimeline
    .set(messageCarousels, { x: 0 })
    .to(messageCarousels, { x: 2000, duration: 40, ease: "linear" });
});

// clock
// https://library.superhi.com/posts/how-to-make-a-clock-with-javascript
var now = new Date();
var hour = now.getHours();
const hourText = (document.querySelector(".hour").innerHTML = `${hour}`);

console.log(hour);
function runClock() {
  var now = new Date();

  var min = now.getMinutes();
  var sec = now.getSeconds();
  var ms = now.getMilliseconds();

  var clock = document.querySelector(".clock");
  var minHand = clock.querySelector(".minute");
  var secHand = clock.querySelector("div.second");

  var minRotation = 6 * min + 0.1 * sec;
  var secRotation = 6 * sec + 0.006 * ms;

  minHand.style.transform = "rotate(" + minRotation + "deg)";
  secHand.style.transform = "rotate(" + secRotation + "deg)";

  requestAnimationFrame(runClock);
}
runClock();
