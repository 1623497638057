import p5 from "p5";

/**************************************/
/* Cell */
// save this file as sketch.js
var cellWidth;
var cellHeight;
// Sketch One
var t = function (p) {
  cellWidth = document.getElementById("cell").offsetWidth;
  cellHeight = document.getElementById("cell").offsetHeight;
  // p could be any variable name
  var cells = [];
  p.setup = function () {
    p.createCanvas(cellWidth, cellHeight);

    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
    cells.push(new Cell());
  };

  p.windowResized = function () {
    p.resizeCanvas(cellWidth, cellHeight);
  };

  p.draw = function () {
    p.background(244, 240, 245, 50);
    for (var i = 0; i < cells.length; i++) {
      cells[i].move();
      cells[i].show();
    }
  };

  p.mousePressed = function () {
    for (var i = cells.length - 1; i >= 0; i--) {
      if (cells[i].clicked(p.mouseX, p.mouseY)) {
        cells.push(cells[i].mitosis());
        cells.push(cells[i].mitosis());
        cells.splice(i, 1);
      }
    }
  };

  var Cell = function (pos, r, c) {
    if (pos) {
      this.pos = pos.copy();
    } else {
      this.pos = p.createVector(p.random(p.width), p.random(p.height));
    }

    this.r = r || 40;
    this.c = c || p.color(93, 158, 116, 100);

    this.clicked = function (x, y) {
      var d = p.dist(this.pos.x, this.pos.y, x, y);
      if (d < this.r) {
        return true;
      } else {
        return false;
      }
    };

    this.mitosis = function () {
      //this.pos.x += random(-this.r, this.r);
      var cell = new Cell(this.pos, this.r * 0.8, this.c);
      return cell;
    };

    this.move = function () {
      var vel = p5.Vector.random2D();
      this.pos.add(vel);
    };

    this.show = function () {
      p.noStroke();
      p.fill(this.c);
      p.ellipse(this.pos.x, this.pos.y, this.r, this.r);
    };
  };
};
var myp5 = new p5(t, "cell");

/**************************************/
/* Distance */
//https://p5js.org/examples/motion-circle-collision.html
var distWidth;
var distHeight;
let max_distance;

var d = function (p) {
  distWidth = document.getElementById("dist").offsetWidth;
  distHeight = document.getElementById("dist").offsetHeight;

  p.setup = function () {
    p.createCanvas(distWidth, distHeight);
    p.noStroke();
    max_distance = p.dist(0, 0, p.width, p.height);
  };

  p.windowResized = function () {
    p.resizeCanvas(distWidth, distHeight);
  };

  class Ball {
    constructor(x, y, r) {
      this.position = new p5.Vector(x, y);
      this.velocity = p5.Vector.random2D();
      this.velocity.mult(2);
      this.r = r;
      this.m = r * 0.1;
    }
    update() {
      this.position.add(this.velocity);
    }

    checkBoundaryCollision() {
      if (this.position.x > p.width - this.r) {
        this.position.x = p.width - this.r;
        this.velocity.x *= -1;
      } else if (this.position.x < this.r) {
        this.position.x = this.r;
        this.velocity.x *= -1;
      } else if (this.position.y > p.height - this.r) {
        this.position.y = p.height - this.r;
        this.velocity.y *= -1;
      } else if (this.position.y < this.r) {
        this.position.y = this.r;
        this.velocity.y *= -1;
      }
    }

    checkCollision(other) {
      // Get distances between the balls components
      let distanceVect = p5.Vector.sub(other.position, this.position);

      // Calculate magnitude of the vector separating the balls
      let distanceVectMag = distanceVect.mag();

      // Minimum distance before they are touching
      let minDistance = this.r + other.r;

      if (distanceVectMag < minDistance) {
        let distanceCorrection = (minDistance - distanceVectMag) / 2.0;
        let d = distanceVect.copy();
        let correctionVector = d.normalize().mult(distanceCorrection);
        other.position.add(correctionVector);
        this.position.sub(correctionVector);

        // get angle of distanceVect
        let theta = distanceVect.heading();
        // precalculate trig values
        let sine = p.sin(theta);
        let cosine = p.cos(theta);

        /* bTemp will hold rotated ball this.positions. You 
           just need to worry about bTemp[1] this.position*/
        let bTemp = [new p5.Vector(), new p5.Vector()];

        /* this ball's this.position is relative to the other
           so you can use the vector between them (bVect) as the 
           reference point in the rotation expressions.
           bTemp[0].this.position.x and bTemp[0].this.position.y will initialize
           automatically to 0.0, which is what you want
           since b[1] will rotate around b[0] */
        bTemp[1].x = cosine * distanceVect.x + sine * distanceVect.y;
        bTemp[1].y = cosine * distanceVect.y - sine * distanceVect.x;

        // rotate Temporary velocities
        let vTemp = [new p5.Vector(), new p5.Vector()];

        vTemp[0].x = cosine * this.velocity.x + sine * this.velocity.y;
        vTemp[0].y = cosine * this.velocity.y - sine * this.velocity.x;
        vTemp[1].x = cosine * other.velocity.x + sine * other.velocity.y;
        vTemp[1].y = cosine * other.velocity.y - sine * other.velocity.x;

        /* Now that velocities are rotated, you can use 1D
           conservation of momentum equations to calculate 
           the final this.velocity along the x-axis. */
        let vFinal = [new p5.Vector(), new p5.Vector()];

        // final rotated this.velocity for b[0]
        vFinal[0].x =
          ((this.m - other.m) * vTemp[0].x + 2 * other.m * vTemp[1].x) /
          (this.m + other.m);
        vFinal[0].y = vTemp[0].y;

        // final rotated this.velocity for b[0]
        vFinal[1].x =
          ((other.m - this.m) * vTemp[1].x + 2 * this.m * vTemp[0].x) /
          (this.m + other.m);
        vFinal[1].y = vTemp[1].y;

        // hack to avoid clumping
        bTemp[0].x += vFinal[0].x;
        bTemp[1].x += vFinal[1].x;

        /* Rotate ball this.positions and velocities back
           Reverse signs in trig expressions to rotate 
           in the opposite direction */
        // rotate balls
        let bFinal = [new p5.Vector(), new p5.Vector()];

        bFinal[0].x = cosine * bTemp[0].x - sine * bTemp[0].y;
        bFinal[0].y = cosine * bTemp[0].y + sine * bTemp[0].x;
        bFinal[1].x = cosine * bTemp[1].x - sine * bTemp[1].y;
        bFinal[1].y = cosine * bTemp[1].y + sine * bTemp[1].x;

        // update balls to screen this.position
        other.position.x = this.position.x + bFinal[1].x;
        other.position.y = this.position.y + bFinal[1].y;

        this.position.add(bFinal[0]);

        // update velocities
        this.velocity.x = cosine * vFinal[0].x - sine * vFinal[0].y;
        this.velocity.y = cosine * vFinal[0].y + sine * vFinal[0].x;
        other.velocity.x = cosine * vFinal[1].x - sine * vFinal[1].y;
        other.velocity.y = cosine * vFinal[1].y + sine * vFinal[1].x;
      }
    }

    display() {
      p.noStroke();
      p.fill(93, 158, 116);
      p.ellipse(this.position.x, this.position.y, this.r * 2, this.r * 2);
    }
  }

  let balls = [new Ball(100, 400, 20), new Ball(700, 400, 80)];
  p.draw = function () {
    p.background(244, 240, 245);

    for (let i = 0; i < balls.length; i++) {
      let b = balls[i];
      b.update();
      b.display();
      b.checkBoundaryCollision();
      balls[0].checkCollision(balls[1]);
    }
  };
};

var myp5 = new p5(d, "dist");

/**************************************/
/* Maze */
//https://www.youtube.com/watch?v=_p5IH0L63wo&list=PLRqwX-V7Uu6ZiZxtDDRCi6uhfTH4FilpH&index=13
// save this file as sketch.js
var mazeWidth;
var mazeHeight;
// Sketch One
var s = function (p) {
  mazeWidth = document.getElementById("maze").offsetWidth;
  mazeHeight = document.getElementById("maze").offsetHeight;
  // p could be any variable name
  var cols, rows;
  var w = 20;
  var grid = [];

  var current;

  var stack = [];

  p.setup = function () {
    p.createCanvas(mazeWidth, mazeHeight);
    cols = p.floor((p.width / w) * 2);
    rows = p.floor(p.height / w + 1);
    p.frameRate(5);

    for (var j = 0; j < rows; j++) {
      for (var i = 0; i < cols; i++) {
        var cellMaze = new CellMaze(i, j);
        grid.push(cellMaze);
      }
    }

    current = grid[0];
  };

  p.windowResized = function () {
    p.resizeCanvas(mazeWidth, mazeHeight);
  };

  p.draw = function () {
    p.background(244, 240, 245);
    for (var i = 0; i < grid.length; i++) {
      grid[i].show();
    }

    current.visited = true;
    current.highlight();
    // Step 1
    var next = current.checkNeighbours();
    if (next) {
      next.visited = true;
      // step 2
      stack.push(current);
      // Step 3
      removeWalls(current, next);

      // Step 4
      current = next;
    } else if (stack.length > 0) {
      current = stack.pop();
    }
  };

  var index = function (i, j) {
    if (i < 0 || j < 0 || i > cols - 1 || j > rows - 1) {
      return -1;
    }

    return i + j * cols;
  };

  var CellMaze = function (i, j) {
    this.i = i;
    this.j = j;
    this.walls = [true, true, true, true];
    this.visited = false;

    this.checkNeighbours = function () {
      var neighbours = [];

      var top = grid[index(i, j - 1)];
      var right = grid[index(i + 1, j)];
      var bottom = grid[index(i, j + 1)];
      var left = grid[index(i - 1, j)];

      if (top && !top.visited) {
        neighbours.push(top);
      }
      if (right && !right.visited) {
        neighbours.push(right);
      }
      if (bottom && !bottom.visited) {
        neighbours.push(bottom);
      }
      if (left && !left.visited) {
        neighbours.push(left);
      }

      if (neighbours.length > 0) {
        var r = p.floor(p.random(0, neighbours.length));
        return neighbours[r];
      } else {
        return undefined;
      }
    };

    this.highlight = function () {
      var x = this.i * w;
      var y = this.j * w;
      p.noStroke();
      p.fill(219, 100, 111);
      p.rect(x, y, w, w);
    };

    this.show = function () {
      var x = this.i * w;
      var y = this.j * w;
      p.stroke(93, 158, 116);
      if (this.walls[0]) {
        p.line(x, y, x + w, y);
      }
      if (this.walls[1]) {
        p.line(x + w, y, x + w, y + w);
      }
      if (this.walls[2]) {
        p.line(x + w, y + w, x, y + w);
      }
      if (this.walls[3]) {
        p.line(x, y + w, x, y);
      }

      if (this.visited) {
        p.noStroke();
        p.fill(93, 158, 116, 200);
        p.rect(x, y, w, w);
      }
    };
  };

  var removeWalls = function (a, b) {
    var x = a.i - b.i;
    if (x === 1) {
      a.walls[3] = false;
      b.walls[1] = false;
    } else if (x === -1) {
      a.walls[1] = false;
      b.walls[3] = false;
    }

    var y = a.j - b.j;
    if (y === 1) {
      a.walls[0] = false;
      b.walls[2] = false;
    } else if (y === -1) {
      a.walls[2] = false;
      b.walls[0] = false;
    }
  };
};
var myp5 = new p5(s, "maze");

/**************************************/
/* Spring */
let body = document.getElementById("body");
let canvasSpring = document.querySelector(".two");
let clicked;
let bob;
let velocity;
let canvas;

body.addEventListener("click", function (e) {
  clicked = false;
  console.log(false);
  canvas.classlist.add("pressed");
});

var a = function (p) {
  let springWidth = document.getElementById("spring").offsetWidth;
  let springHeight = document.getElementById("spring").offsetHeight;
  let body = document.getElementById("body");
  // p could be any variable name

  let anchor;

  let restLength = springHeight / 1.5;
  let k = 0.01;
  let gravity;

  p.setup = function () {
    canvas = p.createCanvas(springWidth, springHeight);
    canvas.mouseClicked(_mouseClicked);

    bob = p.createVector(springHeight, 0);
    anchor = p.createVector(springWidth / 2, 0);
    velocity = p.createVector(0, 0);
    gravity = p.createVector(0, 0.1);
  };

  p.windowResized = function () {
    p.resizeCanvas(springWidth, springHeight);
  };

  function _mouseClicked() {
    clicked = true;
    console.log(true);
  }

  p.draw = function () {
    p.background(244, 240, 245, 50);
    p.strokeWeight(4);
    p.stroke(93, 158, 116);
    p.line(anchor.x, anchor.y, bob.x, bob.y);
    p.fill(93, 158, 116);
    p.circle(anchor.x, anchor.y, 12);
    p.circle(bob.x, bob.y, springWidth / 8);

    if (p.mouseIsPressed && clicked) {
      bob.x = p.mouseX;
      bob.y = p.mouseY;
      velocity.set(0, 0);
    }

    let force = p5.Vector.sub(bob, anchor);
    let x = force.mag() - restLength;
    force.normalize();
    force.mult(-1 * k * x);

    // F = A
    velocity.add(force);
    velocity.add(gravity);
    bob.add(velocity);
    velocity.mult(0.99);
  };
};

var myp5 = new p5(a, "spring");

/**************************************/
/* Square */
var squareWidth;
var squareHeight;

var b = function (p) {
  squareWidth = document.getElementById("square").offsetWidth;
  squareHeight = document.getElementById("square").offsetHeight;

  // State of spiral
  let x, y;
  let px, py;
  let step = 1;
  let state = 0;
  let numSteps = 1;
  let turnCounter = 1;

  // Scale / resolution
  let stepSize = 5;
  let totalSteps;

  // Function to test if number is prime
  function isPrime(value) {
    if (value == 1) return false;
    for (let i = 2; i <= p.sqrt(value); i++) {
      if (value % i == 0) {
        return false;
      }
    }
    return true;
  }

  p.setup = function () {
    p.createCanvas(squareWidth, squareHeight);

    // set up spiral
    const cols = p.width / stepSize;
    const rows = p.height / stepSize;
    totalSteps = cols * rows;
    x = p.width / 2;
    y = p.height / 2;
    px = x;
    py = y;
    p.background(244, 240, 245);
  };

  p.windowResized = function () {
    p.resizeCanvas(squareWidth, squareHeight);
  };

  p.draw = function () {
    // If prime draw circle
    if (isPrime(step)) {
      p.fill(93, 158, 116);
      p.stroke(93, 158, 116);
      p.circle(x, y, stepSize * 0.5);
    }

    // Connect current to previous with a line
    p.line(x, y, px, py);
    px = x;
    py = y;

    // Move according to state
    switch (state) {
      case 0:
        x += stepSize;
        break;
      case 1:
        y -= stepSize;
        break;
      case 2:
        x -= stepSize;
        break;
      case 3:
        y += stepSize;
        break;
    }

    // Change state
    if (step % numSteps == 0) {
      state = (state + 1) % 4;
      turnCounter++;
      if (turnCounter % 2 == 0) {
        numSteps++;
      }
    }
    step++;
  };

  // Are we done?
  if (step > totalSteps) {
    p.noLoop();
  }
};

var myp5 = new p5(b, "square");
